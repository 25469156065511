import React from "react";
import { graphql } from "gatsby";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import loadable from "@loadable/component";
import Layout from "../components/Layout";
import PostPreview from "../components/PostPreview";

const Pagination = loadable(() => import("../components/ArchivePagination"));

const BlogListing = ({ data, pageContext }) => {
  const posts = data.allContentfulBlogPost.edges;

  return (
    <Layout bodyClass="home blog wp-embed-responsive has-no-pagination showing-comments hide-avatars footer-top-visible customize-support">
      <GatsbySeo
        title="News | American Casinos"
        description="American Casinos"
        canonical={`https://american-casinos.com/news/`}
      />
      {posts
        ? posts.map((post, index) => {
            return <PostPreview key={index} post={post} isLast={index === posts.length - 1} />;
          })
        : null}
      <Pagination numPages={pageContext.numPages} currentPage={pageContext.currentPage} slugPrefix="/news" />
    </Layout>
  );
};

export const pageQuery = graphql`
  query BlogListingQuery($skip: Int!, $limit: Int!) {
    allContentfulBlogPost(skip: $skip, limit: $limit, sort: { fields: createdDate, order: DESC }) {
      edges {
        node {
          slug
          description {
            description
          }
          image {
            gatsbyImage(width: 200, quality: 70, formats: [AUTO, WEBP], placeholder: NONE)
          }
          title
          createdDate(formatString: "DD.MM.YYYY")
        }
      }
    }
  }
`;

export default BlogListing;
