import React from "react";
import { Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import PostMeta from "./PostMeta";

const PostPreview = ({ post, isLast }) => {
  const featuredImage = getImage(post.node.image);
  return (
    <>
      <article
        className={`post-${post.databaseId} post type-post status-publish format-standard has-post-thumbnail hentry category-uncategorized archive-post`}
        id={`post-${post.databaseId}`}
      >
        <header className="has-text-align-center">
          <div className="entry-header-inner section-inner medium">
            {post.image !== null && <GatsbyImage image={featuredImage} alt={post.node.title} className="featured" />}
            <h2 className="entry-title heading-size-2">
              <Link to={`/${post.node.slug}`} dangerouslySetInnerHTML={{ __html: post.node.title }} />
            </h2>
            <PostMeta title={post.title} date={post.node.createdDate} />
          </div>
        </header>

        <div className="post-inner thin ">
          <div className="entry-content">
            <div dangerouslySetInnerHTML={{ __html: post.node.description.description }} />
          </div>
        </div>
      </article>

      {!isLast && (
        <hr
          key={`${post.postId}-hr`}
          className="post-separator styled-separator is-style-wide section-inner"
          aria-hidden="true"
        />
      )}
    </>
  );
};

export default PostPreview;
